import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import InvistaLogo from "../images/logos/invista-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/card-img/invista-min.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Roda de Conversa de Investimentos</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Roda de Conversa sobre Investimentos (Invista como uma garota)"
        date = "Outubro/2019"
        description = "Roda de conversa sobre investimentos e educação financeira, em parceria com o Invista como uma garota. O evento colocou em pauta a relação da mulher com o dinheiro e os investimentos, a importância da educação financeira e da existência de referências femininas diante das dificuldades enfrentadas no ambiente financeiro. A roda teve 2h de duração e foram atingidas mais de 10 mulheres de diferentes faculdades da Universidade de São Paulo."
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={InvistaLogo} alt="" className="partner-logo"/>
                <p className="partner-name">Invista como uma Garota</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
